<template>
  <div>
    <v-row class="mt-2 py-4 px-2 justify-end">
      <v-btn
        class="primary"
        @click="showCreateCollaborator = true"
        @cancel="showCreateCollaborator = false"
      >
        {{ $t('c.createButton') }}
      </v-btn>
    </v-row>

    <v-data-table
      dense
      :headers="headers"
      :items="users"
      :loading="loading"
      item-key="id"
      class="pt-8"
      loading-text="Loading... Please wait"
      :server-items-length="totalItems"
      :footer-props="{
        itemsPerPageText: $t('perPage'),
        itemsPerPageOptions: [5,20,40]
      }"
      :options="options"
      @pagination="handlePagination"
    />
    <create-entity-admin
      v-if="showCreateCollaborator"
      :entity-id="entityId"
      @cancel="showCreateCollaborator = false"
      @collaboratorCreated="handleCollaboratorCreated"
    />
  </div>
</template>

<script>
import UsersRepository from '@/api/UsersRepository'
import CreateEntityAdmin from '@/views/dashboard/components/Users/EntityAdminCreate'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'EntityCollaboratorsList',
  components: { CreateEntityAdmin },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      users: [],
      search: '',
      headers: [
        { text: capitalize(this.$t('name')), value: 'name' },
        { text: 'Email', value: 'email' },
        { text: capitalize(this.$t('role')), value: 'role.slug', align: 'end' },
      ],
      showCreateCollaborator: false,
      loading: true,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 20,
      },

    }
  },
  watch: {
    options: {
      handler (newValue, oldValue) {
        if (newValue.itemsPerPage !== oldValue.itemsPerPage) {
          this.getUsers()
        }
        if (newValue.page !== oldValue.page) {
          this.getUsers()
        }
      },
      deep: true,
    },
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      UsersRepository.index({
        search: this.search,
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        with: 'userRole',
        entity: this.entityId,
      })
        .then(({ data }) => {
          this.users = data.data
          this.totalItems = data.meta.total
          this.loading = false
        })
        .catch(() => {
        })
    },
    handleCollaboratorCreated () {
      this.loading = true
      this.showCreateCollaborator = false
      this.getUsers()
    },
    handlePagination (options) {
      this.options = options
    },
    show (id) {
      this.$router.push({
        name: 'user.show',
        params: {
          userId: id,
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          createButton: 'Create Collaborator',
        },
      },
      it: {
        c: {
          createButton: 'Creare Collaboratore',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
