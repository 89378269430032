<template>
  <v-dialog
    persistent
    :value="true"
    width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('c.title') }}
      </v-card-title>
      <v-card-text>
        <v-form v-if="entity">
          <v-text-field
            v-model="entity.name"
            :label="$t('entity') | capitalize"
            disabled
          />
          <v-text-field
            v-model="user.name"
            :label="$t('Name') | capitalize"
            :loading="requesting"
            :error-messages="errors.name"
          />
          <v-text-field
            v-model="user.email"
            label="Email"
            :loading="requesting"
            :error-messages="errors.email"
          />
        </v-form>
        <div
          v-else
          class="text-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="$emit('cancel')"
        >
          {{ $t('back') }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="validate"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UsersRepository from '@/api/UsersRepository'
import EntitiesRepository from '@/api/EntitiesRepository'
import { rolesIds } from '@/roles'

export default {
  name: 'EntityAdminCreate',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      user: {
        name: '',
        email: '',
      },
      entity: null,
      show: true,
      requesting: false,
      errors: {
        name: null,
        email: null,
      },
    }
  },
  computed: {
    body () {
      return {
        name: this.user.name,
        email: this.user.email,
        user_role_id: rolesIds.entityAdmin,
        entity_id: this.entity.id,
      }
    },
  },
  watch: {
    show () {
      this.user.name = ''
      this.user.email = ''
    },
  },
  created () {
    EntitiesRepository.show(this.entityId)
      .then(({ data }) => {
        this.entity = data
      })
  },
  methods: {
    validate () {
      this.errors.name = this.user.name === '' ? 'Name is required' : null
      this.errors.email = this.user.email === '' ? 'Email is required' : null
      const numErrors = Object.keys(this.errors).filter(key => this.errors[key] !== null)
      if (numErrors.length === 0) {
        this.submit()
      }
    },
    submit () {
      this.requesting = true
      UsersRepository.store(this.body)
        .then(({ data }) => {
          this.$emit('collaboratorCreated')
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const { data } = response
            if (data.errors.name) {
              this.errors.name = data.errors.name[0]
            }
            if (data.errors.email) {
              this.errors.email = data.errors.email[0]
            }
          }
        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          title: 'Creating Entity Admin',
        },
      },
      it: {
        c: {
          title: 'Creazione Entity Admin',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
